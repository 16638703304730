import React from 'react';
import styled from '@emotion/styled';
import { HeaderTitle, HeaderSubtitle, theme, SectionWrapper as SectionWrapperStyles } from '@styles';
import { FeaturesHeaderSectionData } from './types';

const { breakpoints } = theme;

const HeaderSection: React.FC<FeaturesHeaderSectionData> = (props) => {
  const { header_title, header_subtitle } = props;

  return (
    <SectionWrapper id='featuresHeader'>
      <HeaderTitle>{header_title}</HeaderTitle>
      <HeaderSutitleWrapper>
        <HeaderSubtitle>{header_subtitle}</HeaderSubtitle>
      </HeaderSutitleWrapper>
    </SectionWrapper>
  );
};

export default HeaderSection;

const SectionWrapper = styled(SectionWrapperStyles)`
  padding: 0 24px;
  margin-bottom: 80px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: 100px;
  }
`;

const HeaderSutitleWrapper = styled.div`
  margin: 0 auto;
`;
