import { AppFeature } from '@pageSections/featuresPage/features/types';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Box from './Box';

type Props = {
  feature: AppFeature | null;
};

const FeatureImages: React.FC<Props> = ({ feature }) => {
  const [featureImages, setFeatureImages] = useState<string[]>([]);
  const [firstImageOrientation, setFirstImageOrientation] = useState<'portrait' | 'landscape' | null>(null);
  const [thirdImageOrientation, setThirdImageOrientation] = useState<'portrait' | 'landscape' | null>(null);

  useEffect(() => {
    if (feature) {
      let imgArray = [];
      const { feature_image_1, feature_image_2, feature_image_3 } = feature;
      imgArray.push(feature_image_1.url);
      feature_image_2?.url && imgArray.push(feature_image_2?.url);
      feature_image_3?.url && imgArray.push(feature_image_3?.url);
      setFeatureImages(imgArray);

      if (feature_image_1 && feature_image_1.url) {
        handleImageDimensions(setFirstImageOrientation, feature_image_1.url);
      }

      if (feature_image_3 && feature_image_3.url) {
        handleImageDimensions(setThirdImageOrientation, feature_image_3.url);
      } else {
        setThirdImageOrientation(null);
      }
    }
  }, [feature]);

  const handleImageDimensions = (setThirdImageOrientation: any, imageUrl: any) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      if (img.width > img.height) {
        setThirdImageOrientation('landscape');
      } else {
        setThirdImageOrientation('portrait');
      }
    };

    img.onerror = (err) => {
      console.error(err);
    };
  };

  return (
    <div>
      <Images>
        {featureImages.map((image, index) => {
          return (
            <ImageWrapper
              width={
                thirdImageOrientation === 'landscape' && index === 2
                  ? '90%'
                  : featureImages.length === 1 && firstImageOrientation === 'landscape'
                  ? '90%'
                  : '50%'
              }
              height={thirdImageOrientation === 'landscape' && index === 2 ? 'auto' : 'auto'}
              mr={index === 0 && featureImages.length > 1 ? '20px' : '0px'}
              isThirdImage={index === 2}
              isLandscape={thirdImageOrientation === 'landscape'}
            >
              <img src={image} />
            </ImageWrapper>
          );
        })}
      </Images>
    </div>
  );
};

export default FeatureImages;

const ImageWrapper = styled(Box)<{ isThirdImage: boolean; isLandscape: boolean }>`
  position: ${({ isThirdImage }) => (isThirdImage ? 'absolute' : 'initial')};
  bottom: ${({ isThirdImage, isLandscape }) =>
    isThirdImage && isLandscape ? '0px' : isThirdImage && !isLandscape ? '0px' : 'initial'};
`;

const Images = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
`;
