import React from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { theme } from '../site/styles';
import FooterSection from '@pageSections/common/footer';
import { IPrismicFeaturesProfessionals, PageContext } from '@appTypes';

import HeaderSection from '@pageSections/featuresPage/header';
import Features from '@pageSections/featuresPage/features';
import CallToActionSection from '@pageSections/common/callToAction';

const { breakpoints } = theme;

const FeaturesPage: React.FC<PageProps<IPrismicFeaturesProfessionals, PageContext>> = ({
  data: { prismicData, prismicFooterData, prismicCtaData },
  pageContext,
  location,
}) => {
  const { locale } = pageContext;
  const data = prismicData!.edges[0].node.data;

  const { meta_title, meta_description, meta_image, meta_url, body1 } = data;

  const featuresData = body1;

  return (
    <>
      <Container style={{ marginTop: 34 }}>
        <SEO
          title={meta_title}
          description={meta_description}
          image={meta_image.url}
          url={meta_url}
          pathname={location.pathname}
          name='Hyperhuman'
          locale={locale}
        />
        <HeaderSection {...data} />
        <Features featuresData={featuresData} />
      </Container>
      <CallToActionSection prismicCtaData={prismicCtaData} />
      <FooterSection prismicFooterData={prismicFooterData} subscribeForm />
    </>
  );
};

export default FeaturesPage;

const Container = styled.div`
  margin-top: 34px;
  margin-bottom: 70px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: 110px;
  }
`;

export const pageQuery = graphql`
  query FeaturesPageQuery($locale: String!) {
    prismicData: allPrismicFeatures(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_keywords
            meta_description
            meta_image {
              url
            }
            meta_url
            header_title
            header_subtitle
            body1 {
              ... on PrismicFeaturesDataBody1Feature {
                items {
                  feature_name
                  feature_image_1 {
                    url
                    alt
                  }
                  feature_image_2 {
                    url
                    alt
                  }
                  feature_image_3 {
                    url
                    alt
                  }
                  feature_icon {
                    url
                  }
                  feature_content {
                    text
                    html
                  }
                  feature_video {
                    url
                  }
                }
                primary {
                  product_theme
                  product_category
                }
              }
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
