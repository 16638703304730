import * as React from 'react';

import { IconProps } from '@appTypes';

function MinusIcon(props: IconProps) {
  const { stroke } = props;
  return (
    <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
      <g stroke={stroke} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'>
        <path d='m12 5v14' />
        <path d='m5 12h14' />
      </g>
    </svg>
  );
}

export default MinusIcon;
