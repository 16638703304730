import * as React from 'react';

import { IconProps } from '@appTypes';

function PlusIcon(props: IconProps) {
  const { stroke } = props;
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5 12H19' stroke={stroke} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}

export default PlusIcon;
