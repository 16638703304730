import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../styles';

const { colors } = theme;

type Props = {
  label?: string;
  backgroundColor?: string;
  color?: string;
  width?: number | 'auto';
  height?: number;
};

const Tag: React.FC<Props> = ({ label = 'Not set', backgroundColor = colors.outerSpace, color = '#fff' }) => {
  return (
    <Container color={color} backgroundColor={backgroundColor}>
      {label}
    </Container>
  );
};

export default Tag;

const Container = styled.div<{ backgroundColor: string; color: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 15.6px;
  border-radius: 8px;
  text-transform: uppercase;
  user-select: none;
`;
