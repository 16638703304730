import MinusIcon from '@assets/icons/MinusIcon';
import PlusIcon from '@assets/icons/PlusIcon';
import { GRANNY_SMITH, MOUNTAIN_MEADOW, MYSTIC } from '@constants';
import { AppFeature } from '@pageSections/featuresPage/features/types';
import React, { useState } from 'react';
import Box from './Box';
import CustomText from './CustomText';
import styled from '@emotion/styled';
import Modal from 'react-modal';
import CloseIcon from '@assets/icons/CloseIcon';
import theme from '../styles/theme';
import FeatureImages from './FeatureImages';

const { breakpoints } = theme;

Modal.defaultStyles.overlay = {
  ...Modal.defaultStyles.overlay,
  display: 'flex',
  alignItems: 'center',
  zIndex: 999,
  position: 'fixed',
  backgroundColor: 'rgba(52,69,78,0.5)',
};

type Props = {
  feature: AppFeature;
  borderTop: string;
  borderBottom: string;
  onExpand: Function;
  isExpanded: boolean;
};

const FeatureItem: React.FC<Props> = ({ feature, borderTop, borderBottom, onExpand, isExpanded }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string>('');

  const { feature_name, feature_icon, feature_content, feature_video } = feature;

  const toggleVideoModal = (videoUrl?: string) => {
    if (videoUrl) {
      setVideoUrl(videoUrl);
    }
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <FeatureItemWrapper borderTop={borderTop} borderBottom={borderBottom} p={'16px 8px 16px 0px'}>
      <FeatureItemHeader
        onClick={() => {
          onExpand(feature);
        }}
        display='flex'
        alignItems={'center'}
      >
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'50px'}
          height={'50px'}
          backgroundColor={MYSTIC}
          borderRadius={'12px'}
          mr={'16px'}
        >
          <img width={30} height={30} src={feature_icon.url} />
        </Box>
        <Box flex={1}>
          <FeatureName>{feature_name}</FeatureName>
        </Box>
        <Box alignSelf={'center'}>
          {isExpanded ? <PlusIcon stroke={GRANNY_SMITH} /> : <MinusIcon stroke={GRANNY_SMITH} />}
        </Box>
      </FeatureItemHeader>

      <ExpandedBox isExpanded={isExpanded}>
        <FeatureImagesWrapper>
          <FeatureImages feature={feature} />
        </FeatureImagesWrapper>
        <div
          dangerouslySetInnerHTML={{
            __html: feature_content.html,
          }}
        />
        {feature_video && feature_video.url && (
          <VideoLink
            onClick={() => {
              toggleVideoModal(feature_video.url);
            }}
          >
            <CustomText color={MOUNTAIN_MEADOW} fontSize={16} lineHeight={'20.8px'} fontWeight={700}>
              WATCH VIDEO
            </CustomText>
          </VideoLink>
        )}
      </ExpandedBox>

      <Modal
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        isOpen={modalIsOpen}
        contentLabel='Example Modal'
        // @ts-ignore
        style={modalContentStyles}
        ariaHideApp={false}
        onRequestClose={() => {
          toggleVideoModal();
        }}
      >
        <ModalInnerContainer>
          <ModalClose>
            <CloseIcon onClick={() => toggleVideoModal()} color={'white'} width={48} height={48} />
          </ModalClose>
          <ModalVideo width={'100%'} height={'100%'} onClick={() => {}} controls autoPlay src={videoUrl} />
        </ModalInnerContainer>
      </Modal>
    </FeatureItemWrapper>
  );
};

export default FeatureItem;

const modalContentStyles = {
  content: {
    inset: '0px',
    zIndex: 999,
    position: 'relative',
    width: '80vw',
    maxWidth: '896px',
    margin: '0 auto',
    padding: '0px',
    border: 'none',
    borderRadius: '14px',
    marginTop: '20px',
    overflow: 'initial',
    background: '#000',
  },
};

const FeatureImagesWrapper = styled(Box)`
  background-color: ${MYSTIC};
  border-radius: 24px;
  width: 100%;
  max-width: 343px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: none;
  }
`;

const ExpandedBox = styled(Box)<{ isExpanded: boolean }>`
  ${({ isExpanded }) => !isExpanded && `position: absolute; opacity: 0;`};
  margin-top: 32px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 16px;
    margin-left: 66px;
    margin-right: 53px;
  }
`;

const FeatureName = styled(CustomText)`
  font-weight: 700;
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.1px;
  margin-right: 27px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const ModalVideo = styled.video`
  border-radius: 14px;
`;

const ModalClose = styled.div`
  position: absolute;
  top: -56px;
  z-index: 999;
  right: 0;
  cursor: pointer;
`;

const ModalInnerContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  & .youtubeVideoPlayer {
    display: flex;
    flex: 1;
    & iframe {
      margin: 0;
      border-radius: 14px;
    }
  }
`;

const VideoLink = styled(Box)`
  display: inline-block;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const FeatureItemHeader = styled(Box)`
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.9;
  }
`;

const FeatureItemWrapper = styled(Box)`
  position: relative;
  z-index: 2;
`;
