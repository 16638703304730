import styled from '@emotion/styled';
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  border,
  BorderProps,
  color,
  ColorProps,
  FlexboxProps,
  flexbox,
  compose,
} from 'styled-system';

type Props = SpaceProps & LayoutProps & BorderProps & ColorProps & FlexboxProps;

const Box = styled.div<Props>`
  ${compose(flexbox, space, layout, border, color)}
`;

export default Box;
