import Box from '@components/Box';
import CustomText from '@components/CustomText';
import FeatureItem from '@components/FeatureItem';
import { MYSTIC, CANDLELIGHT, OUTER_SPACE } from '@constants';
import React, { useEffect, useState } from 'react';
import { AppFeature, PrismicAppFeature } from './types';
import styled from '@emotion/styled';
import { useWindowScroll } from 'react-use';
import FeatureImages from '@components/FeatureImages';
import { theme } from '@styles';
import Tag from '@components/Tag';

const { breakpoints } = theme;

type Props = {
  featuresData: PrismicAppFeature[];
};

const Features: React.FC<Props> = ({ featuresData }) => {
  const { y } = useWindowScroll();
  const [sidebarSticky, setSidebarSticky] = useState<boolean>(false);
  const [expandedFeature, setExpandedFeature] = useState<AppFeature | null>(null);

  const setPlaceholderBoxPosition = (value: number) => {
    const featuresPlaceholderBox = document.getElementById('featuresPlaceholderBox');
    if (featuresPlaceholderBox) {
      if (value > 0) {
        featuresPlaceholderBox.style.position = 'absolute';
        featuresPlaceholderBox.style.top = `${value}px`;
      } else {
        featuresPlaceholderBox.style.position = 'relative';
        featuresPlaceholderBox.style.top = `${0}px`;
      }
    }
  };

  const handleStickySidebarScroll = (scrollY: number) => {
    const featuresWrapperDom = document.getElementById('featuresWrapper');
    const featuresWrapperOffsetTop = featuresWrapperDom?.offsetTop;
    const featuresWrapperHeight = featuresWrapperDom?.offsetHeight;
    const footerOffsetTop = document.getElementById('cta-footer')?.offsetTop;
    const featuresPlaceholderBox = document.getElementById('featuresPlaceholderBox');
    const featuresPlaceholderBoxHeight = featuresPlaceholderBox?.offsetHeight;

    const MIN_FEATURES_WRAPPER_HEIGHT = 700;

    if (featuresWrapperHeight && featuresWrapperHeight > MIN_FEATURES_WRAPPER_HEIGHT) {
      if (featuresWrapperOffsetTop && footerOffsetTop && featuresPlaceholderBoxHeight) {
        const endOfFeaturesWrapper = scrollY >= footerOffsetTop - featuresPlaceholderBoxHeight - 160;
        if (scrollY > featuresWrapperOffsetTop && scrollY < footerOffsetTop && !endOfFeaturesWrapper) {
          setPlaceholderBoxPosition(0);
          setSidebarSticky(true);
        } else if (scrollY < featuresWrapperOffsetTop) {
          setSidebarSticky(false);
        } else if (endOfFeaturesWrapper) {
          setPlaceholderBoxPosition(featuresWrapperDom.scrollHeight - 120);
          setSidebarSticky(false);
        }
      }
    }
  };

  useEffect(() => {
    handleStickySidebarScroll(y);
  }, [expandedFeature]);

  useEffect(() => {
    handleStickySidebarScroll(y);
  }, [y]);

  useEffect(() => {
    handleExpandFeature(featuresData[0].items[0]);
  }, []);

  const handleExpandFeature = (feature: AppFeature) => {
    if (feature === expandedFeature) {
      setExpandedFeature(null);
    } else {
      setExpandedFeature(feature);
    }
  };

  return (
    <FeaturesWrapper id='featuresWrapper'>
      <FeaturesList>
        {featuresData.map((data, index) => (
          <ProductThemeWrapper key={index} flex={1}>
            <Box mb={'32px'} display='flex' justifyContent={'space-between'} alignItems='center'>
              <CustomText
                fontWeight={700}
                fontFamily={'Poppins'}
                fontSize={40}
                lineHeight={'42px'}
                letterSpacing={'-0.2px'}
              >
                {data.primary.product_theme}
              </CustomText>
              {data.primary.product_category && (
                <Tag
                  height={30}
                  backgroundColor={CANDLELIGHT}
                  color={OUTER_SPACE}
                  label={data.primary.product_category}
                />
              )}
            </Box>
            <Box mb={'80px'}>
              {data.items.map((feature, index) => (
                <FeatureItem
                  key={index}
                  borderTop={index !== 0 ? `1px solid ${MYSTIC}` : 'none'}
                  borderBottom={index === data.items.length - 1 ? `1px solid ${MYSTIC}` : ''}
                  feature={feature}
                  isExpanded={expandedFeature === feature}
                  onExpand={handleExpandFeature}
                />
              ))}
            </Box>
          </ProductThemeWrapper>
        ))}
      </FeaturesList>
      <PlaceholderWrapper isSticky={sidebarSticky} id='featuresPlaceholderWrapper'>
        <PlaceholderBox id='featuresPlaceholderBox'>
          <FeatureImages feature={expandedFeature} />
        </PlaceholderBox>
      </PlaceholderWrapper>
    </FeaturesWrapper>
  );
};

export default Features;

const ProductThemeWrapper = styled(Box)`
  @media screen and (min-width: ${breakpoints.largeDesktop}) {
    margin-right: 43px;
  }
`;

const FeaturesList = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: ${breakpoints.largeDesktop}) {
    max-width: 588px;
  }
`;

const FeaturesWrapper = styled(Box)`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    padding: 0 48px;
  }
  @media screen and (min-width: ${breakpoints.largeDesktop}) {
    padding: 0px;
  }
`;

const PlaceholderBox = styled(Box)`
  margin-top: 24px;
  background-color: ${MYSTIC};
  border-radius: 24px;
  border-radius: 24px;
  width: 548px;
  height: 637px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlaceholderWrapper = styled(Box)<{ isSticky: boolean }>`
  display: none;
  @media screen and (min-width: ${breakpoints.largeDesktop}) {
    display: initial;
    ${({ isSticky }) =>
      isSticky
        ? `
      left: 50%;
      transform: translateX(-50%);
      position: fixed;
      top: 0;
  `
        : ''};
    z-index: 1;
    width: 1200px;
    right: 0;
    display: flex;
    justify-content: flex-end;
  }
`;
